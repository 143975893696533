<template>
  <!-- 基础信息 -->
  <div class="base-info-wrapper">
    <!-- 商品信息 -->
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="10" border>
      <el-descriptions-item label="订单号" :span="2" label-class-name="my-label">
        {{ $route.params.id }}
        <el-tag type="primary" v-if="detailInfo.orderType == 1" size="mini">普通订单 </el-tag>
        <el-tag type="warning" v-if="detailInfo.orderType == 2" size="mini">门店订单 </el-tag>
        <el-tag type="success" v-if="detailInfo.orderType == 3" size="mini">卡券订单 </el-tag>
        <el-tag type="info" size="mini" style="margin-left: 5px;">{{ detailInfo.statusName }}</el-tag>
        <el-tag type="danger" v-if="detailInfo.enableAlipayFundAuth" size="mini" style="margin-left: 5px">免押</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="商品描述" :span="3" label-class-name="my-label">
        <template v-if="detailInfo.orderGoods">
          <div class="handle">
            <span>
              {{ detailInfo.orderGoods.skuTitle }}
              <el-tag :type="detailInfo.orderGoods.goodsPurityName == '全新' ? 'primary' : 'warning'" size="mini">
                {{ detailInfo.orderGoods.goodsPurityName }}
              </el-tag>
            </span>
            <span>
              <el-button
                v-show="detailInfo.orderType != 2 && isShowBtn(AUTH_BTN.review_list_update)"
                size="mini"
                type="primary"
                @click="
                  $router.push({
                    name: 'ReviewUpdateGoods',
                    params: { id: $route.params.id },
                    query: { memberId: $route.query.memberId }
                  })
                "
                >修改</el-button
              >
            </span>
          </div>
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="门店信息" :span="3" label-class-name="my-label">
        <div v-if="detailInfo.store">
          {{ detailInfo.store.storeName }}
          <el-tag :key="item" v-for="item in detailInfo.store.storeTagList" type="danger" style="margin-left: 5px;" size="mini">{{item}}</el-tag>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="监管锁费" :span="1" label-class-name="my-label">{{ detailInfo.store ? detailInfo.store.lockAmount : '' }}</el-descriptions-item>
      <el-descriptions-item label="下单时间" :span="1" label-class-name="my-label">{{ detailInfo.createTime }}</el-descriptions-item>
      <!-- 第二排 -->
      <el-descriptions-item label="费率" :span="1" label-class-name="my-label">
        <div class="priceRate">
          <span>{{ detailInfo.priceRate }} %</span>
          <span v-if="detailInfo.orderType == 2">
            <el-button
              v-show="isShowBtn(AUTH_BTN.review_list_detail_update_price_rate)"
              type="primary"
              size="mini"
              @click="updatePriceRate"
              >修改</el-button
            >
          </span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="零售价" :span="1" label-class-name="my-label">
        <div class="handle">
          <span> {{ detailInfo.salePrice }}</span>
          <span>
            <el-button
                v-show="isShowBtn(AUTH_BTN.review_list_detail_update_cost_price)"
                size="mini"
                type="primary"
                @click="updatePrice"
            >修改</el-button
            >
          </span>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="首付租金" :span="2" label-class-name="my-label">{{ detailInfo.prepayRentPrice }}</el-descriptions-item>
      <el-descriptions-item label="租期" :span="1" label-class-name="my-label">
        <div class="handle">
          <span> {{ detailInfo.billPeriodNum }}期</span>
          <!-- <span>
            <el-button
              v-show="detailInfo.orderType == 2 && isShowBtn(AUTH_BTN.review_list_detail_update_bill_period_num)"
              size="mini"
              type="primary"
              @click="updatePeriodNum"
              >修改</el-button
            >
          </span> -->
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="总租金" :span="1" label-class-name="my-label">{{ detailInfo.rentPrice }}</el-descriptions-item>
      <el-descriptions-item label="平台服务费" :span="1" label-class-name="my-label">{{ detailInfo.servicePrice }}</el-descriptions-item>
      <el-descriptions-item label="保险金" :span="1" label-class-name="my-label">{{ detailInfo.insurancePrice }}</el-descriptions-item>
      <el-descriptions-item label="买断金" :span="1" label-class-name="my-label">
        <div class="handle">
          <span> {{ detailInfo.buyoutPrice }}</span>
          <!-- <span>
            <el-link
              v-show="detailInfo.orderType == 1 && isShowBtn(AUTH_BTN.review_list_update)"
              :underline="false"
              type="primary"
              @click="$router.push({ name: 'ReviewUpdateGoods', params: { id: $route.params.id }, query: { memberId: $route.query.memberId } })"
              >修改</el-link
            >
          </span> -->
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 身份信息 -->
    <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="6" border>
      <el-descriptions-item label="姓名" :span="1" label-class-name="my-label">{{ member.memberName }}</el-descriptions-item>
      <el-descriptions-item label="手机号" :span="1" label-class-name="my-label">{{ member.mobile }}</el-descriptions-item>
      <el-descriptions-item v-if="detailInfo.status == 1" label="用户库类别" :span="1" label-class-name="my-label">
        <span v-for="(item, i) in detailInfo.rosterLevelList" :key="i">
          <el-tag v-if="item == 1" style="margin-right:5px" type="primary" size="small">白名单</el-tag>
          <el-tag v-if="item == 2" style="margin-right:5px" type="danger" size="small">黑名单</el-tag>
          <el-tag v-if="item == 3" style="margin-right:5px" type="info" size="small">灰名单</el-tag>
        </span>
        <el-tag v-if="detailInfo.rosterLevelList == 0" style="margin-right:5px" size="small" type="primary">无数据</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="身份证号" :span="1" label-class-name="my-label">
        {{ member.identityNoPlain }}
        <el-tag type="danger" size="small">历史支付：{{ detailInfo.idCardHistoryPayNum }}笔</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="身份证照片" :span="2" label-class-name="my-label">
        <el-image
          v-if="member.identityFrontImageUrl"
          style="width: 60px; height: 60px;margin-right:10px;"
          :src="member.identityFrontImageUrl"
          :preview-src-list="[member.identityFrontImageUrl]"
        >
        </el-image>
        <el-image
          v-if="member.identityBackImageUrl"
          style="width: 60px; height: 60px;"
          :src="member.identityBackImageUrl"
          :preview-src-list="[member.identityBackImageUrl]"
        >
        </el-image>
      </el-descriptions-item>

      <!-- <el-descriptions-item label="性别" :span="1" label-class-name="my-label">{{member.gradeName}}</el-descriptions-item> -->
      <el-descriptions-item label="年龄" :span="1" label-class-name="my-label">{{ member.age }}</el-descriptions-item>
      <el-descriptions-item label="地区" :span="2" label-class-name="my-label">{{ detailInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="所在位置经纬度" :span="3" label-class-name="my-label" content-class-name="my-content">
        <div v-if="detailInfo.lng" class="handle">
          <span>经度：{{ detailInfo.lng }}；纬度：{{ detailInfo.lat }}</span>
          <span>
            <el-button size="mini" type="primary" @click="showPosition">查看</el-button>
          </span>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 地图 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="50%" @closed="closed">
      <GdMapMarker
        ref="mapMarker"
        :lngStart="detailInfo.lng"
        :latStart="detailInfo.lat"
        :latEnd="orderAddress.lat"
        :lngEnd="orderAddress.lng"
      ></GdMapMarker>
    </el-dialog>
    <!-- 费率 -->
    <el-dialog title="费率" :visible.sync="priceRateDialog" width="800px">
      <!-- <el-radio-group v-model="storeComboData.storeComboId">
        <el-radio style="margin-bottom:10px" v-for="(item, i) in storeComboList" :key="i" :label="item.id">{{ item.comboName }}</el-radio>
      </el-radio-group> -->
      <div class="fei-box">
        <div class="fei-item" v-for="(item, i) in storeComboList" :key="i">
          <div class="title">等级{{ item.gradeType }}</div>
          <el-radio-group v-model="storeComboData.storeComboId">
            <el-radio style="margin-bottom:10px" v-for="(item, i) in item.comboList" :key="i" :label="item.id">{{
              item.comboName
            }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="priceRateDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savePriceRate" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 零售价 -->
    <el-dialog title="修改零售价" :visible.sync="periodDialog" width="800px">
      <el-form ref="periodForm" :model="priceData" label-width="80px">
        <el-form-item label="新零售价">
          <el-input-number
            style="width:200px"
            v-model="priceData.salePrice"
            size="small"
            :precision="2"
            :min="0"
            :step="1"
            step-strictly
          ></el-input-number>
          <!-- <span style="margin-left:5px;font-size:16px">%</span> -->
          <!-- <span class="tips">*输入区间：15-50</span> -->
        </el-form-item>
        <!-- <el-form-item label="">
          <el-button @click="servicePriceRateDialog = false" size="small">取 消</el-button>
          <el-button type="primary" @click="saveServicePriceRate" size="small">确 定</el-button>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button @click="periodDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savePrice" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import GdMapMarker from '@/components/GdMap/gd-map-marker.vue'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { updateServicePriceRateAPI, updatePriceAPI, getStoreComboListAPI, updateStoreComboAPI } from './api'
export default {
  mixins: [authBtnMixin],
  name: 'BaseInfo',
  props: {
    detailInfo: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  inject: ['reload'],
  components: { GdMapMarker },
  data() {
    return {
      periodDialog: false,
      dialogVisible: false,
      priceRateDialog: false,
      storeComboData: {
        orderNo: '',
        storeComboId: ''
      },
      priceData: {
        salePrice: '',
        orderNo: ''
      },
      storeComboList: []
    }
  },
  computed: {
    member() {
      let mem = {}
      if (this.detailInfo.member) {
        mem = this.detailInfo.member
      }
      return mem
    },
    orderAddress() {
      let addr = {}
      if (this.detailInfo.orderAddress) {
        addr = this.detailInfo.orderAddress
      }
      return addr
    }
  },
  mounted() {},

  methods: {
    showPosition() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.mapMarker.initMap()
      })
    },
    closed() {
      // this.reload()
    },
    // 打开修改服务费率窗口
    updatePriceRate() {
      this.priceRateDialog = true
      this.storeComboData.orderNo = this.$route.params.id
      this.storeComboData.storeComboId = this.detailInfo.storeComboId
      this.getStoreComboList()
    },
    // 保存修改的服务费率
    savePriceRate() {
      updateStoreComboAPI(this.storeComboData).then(() => {
        localStorage.setItem('is_update_period', 'true')
        this.$emit('update:flag', false)
        this.$emit('updateDetail')
        this.$message.success('修改成功')
        this.priceRateDialog = false
      })
    },
    // 打开修改零售价
    updatePrice() {
      this.priceData.orderNo = this.$route.params.id
      this.priceData.salePrice = this.detailInfo.salePrice
      this.periodDialog = true
    },
    // 保存零售价
    savePrice() {
      updatePriceAPI(this.priceData).then(() => {
        localStorage.setItem('is_update_period', 'true')
        this.$emit('update:flag', false)
        this.$emit('updateDetail')
        this.$message.success('修改成功')
        this.periodDialog = false
      })
    },
    // 门店套餐列表
    async getStoreComboList() {
      let param = {comboGrade: this.detailInfo.orderScoreProfile?.finalHitGrade}
      this.storeComboList = await getStoreComboListAPI(param)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-info-wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
    .priceRate {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .tips {
    margin-left: 20px;
    color: #f56c6c;
  }
  .el-dialog__footer {
    padding-bottom: 30px !important;
  }
  .fei-box {
    .fei-item {
      margin-bottom: 10px;
      .title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
