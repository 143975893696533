<template>
  <div class="wrapper">
    <div class="list">
      <div class="item" v-for="(item, i) in list" :key="i">
        <div class="title">{{ item.optionName }}</div>
        <div class="bill">
          <span class="col1">
            <el-radio v-model="storeBillIndex" :label="i" @click.native.prevent="changeType(i)">{{ '' }}</el-radio>
          </span>
          <span class="col2">
            <el-checkbox disabled v-for="(bill, index) in item.billList" :key="index" v-model="bill.isPrepay">
              <span>第{{ bill.billNum }}期（￥：{{ bill.billPrice }}）</span>
              <span>
                <el-button
                  :disabled="storeBillIndex != i"
                  style="margin-left:5px"
                  v-if="bill.billNum == 1 && bill.isPrepay"
                  type="primary"
                  size="mini"
                  @click="updatePrice(item, bill.billPrice)"
                  >修改</el-button
                >
              </span>
            </el-checkbox>
          </span>
        </div>
        <div class="first-price">
          首付金额：<span>{{ item.prepayPrice }}元</span>
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog title="第一期金额修改" :visible.sync="dialogVisible" width="800px">
      <el-form ref="form" :model="formData" label-width="120px">
        <el-form-item label="新第一期金额">
          <el-input-number
            style="width:200px"
            v-model="formData.fixFirstBillPrice"
            size="small"
            :precision="2"
            :min="0"
            :step="0.01"
            step-strictly
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePrice">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateStoreFirstPriceAPI } from './api'
export default {
  name: 'StorePeriod',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: false,
      // type: 0,
      storeBillIndex: 0,
      formData: {
        orderNo: this.$route.params.id,
        storeComboOption: '',
        fixFirstBillPrice: ''
      }
    }
  },
  watch: {},
  created() {
    localStorage.setItem('storeBillIndex:' + this.formData.orderNo, this.storeBillIndex)
  },
  mounted() {},

  methods: {
    updatePrice({ optionType }, billPrice) {
      this.formData.storeComboOption = optionType
      this.formData.fixFirstBillPrice = billPrice
      this.dialogVisible = true
    },
    async savePrice() {
      const res = await updateStoreFirstPriceAPI(this.formData)
      this.$emit('resetBill', { bill: res, index: this.storeBillIndex })
      this.$message.success('修改成功')
      this.dialogVisible = false
    },
    changeType(i) {
      const origin = JSON.parse(localStorage.getItem('storeBill:' + this.formData.orderNo))
      const origin_bill = origin[this.storeBillIndex]
      this.$confirm('修改方案系统将会重置账单金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$emit('resetBill', { bill: origin_bill, index: this.storeBillIndex })
          localStorage.setItem('storeBillIndex:' + this.formData.orderNo, i)
          this.storeBillIndex = i
          this.$store.commit('UPDATE_PERIOD', this.list[this.storeBillIndex].prepayBill)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          })
        })
    }
  },
  beforeDestroy() {
    console.log('销毁')
    localStorage.removeItem('updateBill:' + this.$route.params.id)
    localStorage.removeItem('storeBillIndex:' + this.$route.params.id)
    localStorage.removeItem('storeBill:' + this.$route.params.id)
  }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  background: #fafafa;
  padding: 15px;
  .list {
    .item {
      margin-bottom: 20px;
      border-bottom: 2px solid #f5f5f5;
      padding-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-bottom: 0;
      }
      .title {
        margin-bottom: 10px;
      }
      .bill {
        display: flex;
        .col1 {
          margin-right: 10px;
        }
        // .col2 {
        // }
        .el-checkbox__input.is-disabled.is-checked {
          .el-checkbox__inner {
            background-color: #66b1ff;
            border-color: #66b1ff;
            &::after {
              border-color: #fafafa;
            }
          }
        }
        .el-checkbox.is-disabled {
          .el-checkbox__label {
            color: #66b1ff;
          }
        }
      }
      .first-price {
        padding-left: 30px;
        margin-top: 10px;
        font-weight: 600;
        span {
          color: #f56c6c;
        }
      }
    }
  }
}
</style>
