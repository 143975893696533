<template>
  <div class="wrapper">
    <el-form ref="formScoreProfile" class="score-profile-form" :model="scoreProfileData" label-width="">
      <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="5" border>
        <el-descriptions-item label="系统评分" :span="1" label-class-name="my-label">
          {{ scoreProfileData.sysHitScore >= 0 ? scoreProfileData.sysHitScore + '分' : '' }}
          <el-button v-if="scoreProfileData.sysHitScore >= 0" style="margin-left:10px" type="primary" size="small" @click="scoreBillList">查看详情</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="系统评级" :span="1" label-class-name="my-label">
          {{ scoreProfileData.sysHitGrade }}
        </el-descriptions-item>
        <el-descriptions-item label="最终评级" :span="1" label-class-name="my-label">
          {{ scoreProfileData.finalHitGrade }}
          <el-button
              v-if="scoreProfileData.sysHitScore >= 0"
              style="margin-left:10px"
              type="primary"
              size="small"
              @click="updateFinalGrade"
              :disabled="!isShowBtn(AUTH_BTN.review_list_detail_update_final_grade)"
          >修改</el-button>
        </el-descriptions-item>
        <el-descriptions-item label="审核建议" :span="2" label-class-name="my-label">
          {{ scoreProfileData.sysHitSuggestDesc }}
        </el-descriptions-item>

        <el-descriptions-item label="芝麻分" span="1" label-class-name="my-label">
          <el-form-item label=" " prop="zmScore" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <el-input style="width:85%;" v-model="scoreProfileData.zmScore" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="信用情况" span="1" label-class-name="my-label">
          <el-form-item label=" " prop="creditStatus" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <SelectDict width="85%" v-model="scoreProfileData.creditStatus" dictCode="member:creditStatus"></SelectDict>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="租机历史" span="1" label-class-name="my-label">
          <el-form-item label=" " prop="rentHistory" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <SelectDict width="85%" v-model="scoreProfileData.rentHistory" dictCode="member:rentHistory"></SelectDict>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="涉诉情况" span="1" label-class-name="my-label">
          <el-form-item label=" " prop="involveStatus" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <SelectDict width="85%" v-model="scoreProfileData.involveStatus" dictCode="member:involveStatus"></SelectDict>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="是否学生在读" span="1" label-class-name="my-label">
          <el-form-item label=" " prop="inSchoolStatus" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <SelectDict width="85%" v-model="scoreProfileData.inSchoolStatus" dictCode="member:inSchoolStatus"></SelectDict>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item label="工作单位" :span="2" label-class-name="my-label">
          <el-form-item label=" " prop="workCompany" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <el-input style="width:85%;" v-model="scoreProfileData.workCompany" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="岗位" :span="1" label-class-name="my-label">
          <el-form-item label=" " prop="jobCategory" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <SelectDict width="85%" v-model="scoreProfileData.jobCategory" dictCode="member:jobCategory"></SelectDict>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="薪资" :span="1" label-class-name="my-label">
          <el-form-item label=" " prop="income" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <el-input style="width:85%;" v-model="scoreProfileData.income" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label=" " :span="1" label-class-name="my-label">
          <el-button type="primary" size="small" @click="saveProfile">保存</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>

    <el-dialog title="系统评分详情" :visible.sync="scoreBillInfoDialog" width="800px">
      <div class="score-bill-title">
        <span>系统等级：{{ scoreProfileData.sysHitGrade }}</span>
        <span>系统评分：{{ scoreProfileData.sysHitScore }}</span>
        <span>系统建议：{{ scoreProfileData.sysHitSuggestDesc }}</span>
      </div>
      <div class="score-bill-table">
        <tp-table :showPagination="false" :isNeedSerialNumber="true" :tableData="scoreBillInfo.billList" :columns="columns" />
      </div>
    </el-dialog>

    <el-dialog title="最终等级修改" :visible.sync="updateFinalGradeDialog" width="800px">
      <div>
        <el-radio-group v-model="finalGradeData.grade">
          <el-radio @click.native.prevent="clickFinalGrade(item)" v-for="item in gradeList" :key="item.value" :label="item.value">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="final-grade-remark">
        <span>备注</span>
        <el-input style="width:350px;" v-model="finalGradeData.remark" size="mini" placeholder="请输入"></el-input>
      </div>
      <div slot="footer">
        <el-button @click="updateFinalGradeDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveFinalGrade" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getScoreBillInfoAPI, hitOrderScoreProfileAPI, updateFinalHitGradeAPI } from './api'
import SelectDict from "@/views/components/select-dict.vue";
import {getDictEnumListAPI} from "@/enum/dict";
import { authBtnMixin } from '@/mixins/authBtnMixin'

const columns = [
  {
    label: '评分项命中规则',
    prop: 'ruleName',
    minWidth: '300',
    customRender(h, row) {
      return <p>{row['ruleName']}</p>
    }
  },
  {
    label: '分值',
    prop: 'hitScore',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['hitScore']}</p>
    }
  },
  {
    label: '审核建议',
    prop: 'hitSuggestDesc',
    minWidth: '100',
    customRender(h, row) {
      return <div>{row['hitSuggestDesc']}</div>
    }
  },
]

export default {
  name: 'ScoreProfile',
  components: { SelectDict },
  mixins: [authBtnMixin],
  props: {
    scoreProfileInfo: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      columns,
      scoreProfileData: {
        orderNo: '',
        sysHitScore: '',
        sysHitSuggestDesc: '',
        sysHitGrade: '',
        finalHitGrade: '',
        zmScore: '',
        creditStatus: '',
        rentHistory: '',
        involveStatus: '',
        inSchoolStatus: '',
        workCompany: '',
        jobCategory: '',
        jobName: '',
        income: '',
      },
      scoreBillInfoDialog: false,
      scoreBillInfo: {},
      gradeList: [],
      updateFinalGradeDialog: false,
      finalGradeData: {
        grade : '',
        remark: '',
      }
    }
  },
  watch: {
    scoreProfileInfo: {
      immediate: true,
      handler(newV, oldV) {
        if (newV && this.flag) {
          this.scoreProfileData = this.scoreProfileInfo
        }
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    scoreBillList() {
      getScoreBillInfoAPI({orderNo: this.scoreProfileData.orderNo}).then(res => {
        this.scoreBillInfoDialog = true
        this.scoreBillInfo = res
      })
    },
    updateFinalGrade() {
      getDictEnumListAPI('member:grade').then(res => {
        this.gradeList = res
        this.finalGradeData.grade = this.scoreProfileData.finalHitGrade
        this.updateFinalGradeDialog = true
      })
    },
    clickFinalGrade(item) {
      this.finalGradeData.grade = item.value
    },
    saveFinalGrade() {
      let param = {
        orderNo: this.scoreProfileData.orderNo,
        grade: this.finalGradeData.grade,
        remark: this.finalGradeData.remark,
      }
      updateFinalHitGradeAPI(param).then(() => {
        this.$message.success('保存成功')
        this.$emit('update:flag', true)
        this.$emit('update:updatePeriod', false)
        this.$emit('updateDetail')
        this.updateFinalGradeDialog = false
      })
    },
    // 保存资料
    saveProfile() {
      this.$refs.formScoreProfile.validate(valid => {
        if (valid) {
          hitOrderScoreProfileAPI(this.scoreProfileData).then(() => {
            this.$message.success('保存成功')
            this.$emit('update:flag', true)
            this.$emit('updateDetail')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .score-profile-form {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-descriptions-item__cell {
    height: 50px;
  }
  .score-bill-title {
    & > span {
      margin-right: 50px;
      font-weight: 600;
    }
  }
  .score-bill-table {
    margin-top: 20px;
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
  .final-grade-remark {
    margin-top: 30px;
    span {
      margin-right: 10px;
    }
  }
}
</style>
