<template>
  <div class="drawStation">
    <!-- <div class="input">
      <div>
        <span>关键字搜索：</span>
        <el-input id="tipinput" v-model="tipinput" size="small"></el-input>
      </div>
    </div> -->
    <div id="markerMap"></div>
  </div>
</template>

<script>
import { key, code } from '@/enum/dict.js'
import AMapLoader from '@amap/amap-jsapi-loader'
import { isEmpty } from '@/utils'
window._AMapSecurityConfig = {
  securityJsCode: code // '「申请的安全密钥」',
}
export default {
  name: 'MapMarker',
  props: ['lngStart', 'latStart', 'latEnd', 'lngEnd'],
  data() {
    return {
      // 地图对象
      // AMap: null,
      // 地图实例对象
      // map: null
      // 经纬度
      // location: {
      //   lat: '',
      //   lng: ''
      // },
      // 搜索输入
      // tipinput: '',
      // auto: null,
      // placeSearch: null
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap()
  },
  methods: {
    /**
     * 创建地图
     */
    initMap() {
      AMapLoader.load({
        key: key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.Driving'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          // this.AMap = AMap
          this.map = new AMap.Map('markerMap', {
            resizeEnable: true,
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 15, //初始化地图级别
            center: [this.lngStart, this.latStart] //初始化地图中心点位置
          })

          // 地图控件
          this.map.addControl(new AMap.Scale())

          // 创建 AMap.Icon 实例：
          let iconStart = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(25, 34),
            // 图标的取图地址
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
            // 图标所用图片大小
            imageSize: new AMap.Size(135, 40),
            // 图标取图偏移量
            imageOffset: new AMap.Pixel(-9, -3)
          })
          let iconEnd = new AMap.Icon({
            size: new AMap.Size(25, 34),
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
            imageSize: new AMap.Size(135, 40),
            imageOffset: new AMap.Pixel(-95, -3)
          })

          let markerStart = new AMap.Marker({
            map: this.map,
            zIndex: 9999999,
            icon: iconStart, // 添加 Icon 实例
            offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
            position: [this.lngStart, this.latStart] // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
          })
          let markerEnd = new AMap.Marker({
            map: this.map,
            zIndex: 9999999,
            icon: iconEnd, // 添加 Icon 实例
            offset: new AMap.Pixel(-13, -30), //icon中心点的偏移量
            position: [this.lngEnd, this.latEnd] // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
          })

          this.map.add(markerStart)
          this.map.add(markerEnd)
          this.map.setFitView()

          let line = new AMap.Polyline({
            strokeColor: '#29b6f6',
            isOutline: true,
            outlineColor: '#29b6f6'
          })
          line.setMap(this.map)

          let text = new AMap.Text({
            text: '',
            style: { 'background-color': '#29b6f6', 'border-color': '#e1f5fe', 'font-size': '12px' }
          })
          text.setMap(this.map)

          var p1 = markerStart.getPosition()
          var p2 = markerEnd.getPosition()
          var textPos = p1.divideBy(2).add(p2.divideBy(2))
          var distance = Math.round(p1.distance(p2))
          var path = [p1, p2]
          line.setPath(path)
          text.setText('两地相距' + distance/1000 + '公里')
          text.setPosition(textPos)

          // let driving = new AMap.Driving({
          //   map: this.map,
          //   // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          //   // eslint-disable-next-line no-undef
          //   policy: AMap.DrivingPolicy.LEAST_TIME
          // })

          // // 根据起终点经纬度规划驾车导航路线
          // driving.search(new AMap.LngLat(this.lngStart, this.latStart), new AMap.LngLat(this.lngEnd, this.latEnd), function(status, result) {
          //   // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          //   if (status === 'complete') {
          //     // that.$message({
          //     //   message: '绘制驾车路线完成',
          //     //   type: 'success'
          //     // })
          //   } else {
          //     // that.$message.error('获取驾车数据失败' + result)
          //   }
          // })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  beforeDestroy() {
    // 销毁地图
    if (this.map) this.map.destroy()
  }
}
</script>

<style lang="scss" scoped>
.drawStation {
  #markerMap {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 400px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .el-input {
      width: 150px;
      margin-right: 20px;
    }
  }
}
</style>
