<template>
  <div class="wrapper">
    <admin-title title="新颜全景雷达"></admin-title>
    <!-- 全景雷达评分 -->
    <div class="score">
      <div class="title">全景雷达评分</div>
      <el-divider></el-divider>
      <div class="proess">
        <div class="item1">
          <span class="name">申请准入分</span>
          <span style="flex:1">
            <el-progress :percentage="zhunru" :format="zhunruFormat" :stroke-width="24" color="#4091f7"></el-progress>
          </span>
        </div>
        <div class="item1">
          <span class="name">贷款行为分</span>
          <span style="flex:1">
            <el-progress :percentage="xingwei" :format="xingweiFormat" :stroke-width="24" color="#4091f7"></el-progress>
          </span>
        </div>
        <div class="item1">
          <span class="name">申请准入置信度</span>
          <span style="flex:1">
            <el-progress :percentage="shenqing" :format="shenqingFormat" :stroke-width="24" color="#4091f7"></el-progress>
          </span>
        </div>
        <div class="item1">
          <span class="name">消金额度置信度</span>
          <span style="flex:1">
            <el-progress :percentage="xiaojin" :format="xiaojinFormat" :stroke-width="24" color="#4091f7"></el-progress>
          </span>
        </div>
        <div class="item1">
          <span class="name">网贷额度置信度</span>
          <span style="flex:1">
            <el-progress :percentage="wangdai" :format="wangdaiFormat" :stroke-width="24" color="#4091f7"></el-progress>
          </span>
        </div>
        <div class="item1">
          <span class="name">贷款行为置信度</span>
          <span style="flex:1">
            <el-progress :percentage="daikuan" :format="daikuanFormat" :stroke-width="24" color="#4091f7"></el-progress>
          </span>
        </div>
        <div class="tip">
          * 数据说明: 准入分/行为分分值区间1-1000分，参考意见520分为优秀，分值越高，用户信用度越佳。
          置信度分值区间均为50-100分，0分表示无数据，参考意见80分以上为优秀，分值越高，用户信用度越佳。
        </div>
      </div>
    </div>
    <!-- 多头报告查询 -->
    <div class="duotou">
      <div class="duotou-title">多头报告查询</div>
      <el-divider></el-divider>
      <div class="content">
        <div class="left">
          <div class="top">机构总查询次数：{{ info.totalOrgQueryNum || '暂无数据' }}</div>
          <div class="bottom">
            <div class="item1">
              <div class="name">近1个月机查笔数</div>
              <div class="value">{{ info.m1OrgQueryNum || '暂无数据' }}</div>
            </div>
            <div class="item1">
              <div class="name">近3个月机查笔数</div>
              <div class="value">{{ info.m3OrgQueryNum || '暂无数据' }}</div>
            </div>
            <div class="item1">
              <div class="name">近6个月机查笔数</div>
              <div class="value">{{ info.m6OrgQueryNum || '暂无数据' }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="name">最后一次查询时间</div>
          <div class="date">{{ info.lastOrgQueryTime || '暂无数据' }}</div>
        </div>
      </div>
    </div>
    <!-- 申请行为数据 -->
    <div class="shenqing">
      <div class="shenqing-title">申请行为数据</div>
      <el-divider></el-divider>
      <div class="content">
        <div class="item1">
          <div class="name">申请准入置信度</div>
          <div class="value">{{ info.applyAccessConfidenceDegree || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">申请命中机构数</div>
          <div class="value">{{ info.applyHitOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">申请命中消金类机构数</div>
          <div class="value">{{ info.applyHitConsumeLoanOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">申请命中网络贷款类机构数</div>
          <div class="value">{{ info.applyHitNetLoanOrgNum || '暂无数据' }}</div>
        </div>
      </div>
    </div>
    <!-- 信用详情 -->
    <div class="xinyong">
      <div class="xinyong-title">信用详情</div>
      <el-divider></el-divider>
      <div class="content">
        <div class="item1">
          <div class="name">网络贷款类机构数</div>
          <div class="value">{{ info.netLoanOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">网络贷款机构最大授信额度</div>
          <div class="value">{{ info.netLoanOrgMaxCreditAmount || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">网络贷款机构平均授信额度</div>
          <div class="value">{{ info.netLoanOrgAvgCreditAmount || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">正常还款订单数占贷款总订单数比例</div>
          <div class="value">{{ info.normalRepayOrderRate || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">最近一次履约距今天数</div>
          <div class="value">{{ info.lastPerformanceRecentlyDay || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">贷款行为置信度</div>
          <div class="value">{{ info.loanBehaviorConfidenceDegree || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">贷款已结清订单数</div>
          <div class="value">{{ info.settledOrderNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">信用贷款时长</div>
          <div class="value">{{ info.creditLoanTime || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">最后一次贷款放款时间</div>
          <div class="value">{{ info.lastLoanDistributeTime || '暂无数据' }}</div>
        </div>
      </div>
      <div class="content-4">
        <div class="item1">
          <div class="name">消金贷款类机构数</div>
          <div class="value">{{ info.consumeLoanOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">消金贷款机构最大授信额度</div>
          <div class="value">{{ info.consumeLoanOrgMaxCreditAmount || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">消金贷款机构平均授信额度</div>
          <div class="value">{{ info.consumeLoanOrgAvgCreditAmount || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">消金额度置信度</div>
          <div class="value">{{ info.consumeLoanConfidenceDegree || '暂无数据' }}</div>
        </div>
      </div>
    </div>
    <!-- 行为雷达报告详情 -->
    <div class="xingwei">
      <div class="xingwei-title">行为雷达报告详情</div>
      <el-divider></el-divider>
      <div class="content">
        <div class="row head">
          <div class="item1">数据名称</div>
          <div class="item1">近1个月</div>
          <div class="item1">近3个月</div>
          <div class="item1">近6个月</div>
          <div class="item1">近12个月</div>
          <div class="item1">近24个月</div>
        </div>
        <div class="row">
          <div class="item1">贷款总金额</div>
          <div class="item1">{{ info.m1LoanAmount }}</div>
          <div class="item1">{{ info.m3LoanAmount }}</div>
          <div class="item1">{{ info.m6LoanAmount }}</div>
          <div class="item1">{{ info.m12LoanAmount }}</div>
          <div class="item1">{{ info.m24LoanAmount }}</div>
        </div>
        <div class="row">
          <div class="item1">贷款笔数</div>
          <div class="item1">{{ info.m1LoanNum }}</div>
          <div class="item1">{{ info.m3LoanNum }}</div>
          <div class="item1">{{ info.m6LoanNum }}</div>
          <div class="item1">{{ info.m12LoanNum }}</div>
          <div class="item1">{{ info.m24LoanNum }}</div>
        </div>
        <div class="row">
          <div class="item1">贷款机构数</div>
          <div class="item1">{{ info.m1LoanOrgNum }}</div>
          <div class="item1">{{ info.m3LoanOrgNum }}</div>
          <div class="item1">{{ info.m6LoanOrgNum }}</div>
          <div class="item1">{{ info.m12LoanOrgNum }}</div>
          <div class="item1">{{ info.m24LoanOrgNum }}</div>
        </div>
        <div class="row">
          <div class="item1">消金类贷款机构数</div>
          <div class="item1">{{ info.m1ConsumeLoanOrgNum }}</div>
          <div class="item1">{{ info.m3ConsumeLoanOrgNum }}</div>
          <div class="item1">{{ info.m6ConsumeLoanOrgNum }}</div>
          <div class="item1">{{ info.m12ConsumeLoanOrgNum }}</div>
          <div class="item1">{{ info.m24ConsumeLoanOrgNum }}</div>
        </div>
        <div class="row">
          <div class="item1">网贷类贷款机构数</div>
          <div class="item1">{{ info.m1NetLoanOrgNum }}</div>
          <div class="item1">{{ info.m3NetLoanOrgNum }}</div>
          <div class="item1">{{ info.m6NetLoanOrgNum }}</div>
          <div class="item1">{{ info.m12NetLoanOrgNum }}</div>
          <div class="item1">{{ info.m24NetLoanOrgNum }}</div>
        </div>
        <div class="row">
          <div class="item1">MO+逾期贷款笔数</div>
          <div class="item1">{{ info.m1M0OverdueLoanNum }}</div>
          <div class="item1">{{ info.m3M0OverdueLoanNum }}</div>
          <div class="item1">{{ info.m6M0OverdueLoanNum }}</div>
          <div class="item1">{{ info.m12M0OverdueLoanNum }}</div>
          <div class="item1">{{ info.m24M0OverdueLoanNum }}</div>
        </div>
        <div class="row">
          <div class="item1">累计逾期金额</div>
          <div class="item1">{{ info.m1TotalOverdueAmount }}</div>
          <div class="item1">{{ info.m3TotalOverdueAmount }}</div>
          <div class="item1">{{ info.m6TotalOverdueAmount }}</div>
          <div class="item1">{{ info.m12TotalOverdueAmount }}</div>
          <div class="item1">{{ info.m24TotalOverdueAmount }}</div>
        </div>
        <div class="row">
          <div class="item1">失败扣款笔数</div>
          <div class="item1">{{ info.m1FailDeductNum }}</div>
          <div class="item1">{{ info.m3FailDeductNum }}</div>
          <div class="item1">{{ info.m6FailDeductNum }}</div>
          <div class="item1">{{ info.m12FailDeductNum }}</div>
          <div class="item1">{{ info.m24FailDeductNum }}</div>
        </div>
        <div class="row">
          <div class="item1">履约贷款总金额</div>
          <div class="item1">{{ info.m1PerformanceLoanAmount }}</div>
          <div class="item1">{{ info.m3PerformanceLoanAmount }}</div>
          <div class="item1">{{ info.m6PerformanceLoanAmount }}</div>
          <div class="item1">{{ info.m12PerformanceLoanAmount }}</div>
          <div class="item1">{{ info.m24PerformanceLoanAmount }}</div>
        </div>
        <div class="row">
          <div class="item1">履约贷款次数</div>
          <div class="item1">{{ info.m1PerformanceLoanNum }}</div>
          <div class="item1">{{ info.m3PerformanceLoanNum }}</div>
          <div class="item1">{{ info.m6PerformanceLoanNum }}</div>
          <div class="item1">{{ info.m12PerformanceLoanNum }}</div>
          <div class="item1">{{ info.m24PerformanceLoanNum }}</div>
        </div>
      </div>
    </div>
    <div style="height:200px"></div>
  </div>
</template>

<script>
export default {
  name: 'XinyanSpray',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  computed: {
    // 申请准入分
    zhunru() {
      const { applyAccessGrade } = this.info
      return (applyAccessGrade / 1000) * 100 || 0
    },
    // 贷款行为分
    xingwei() {
      const { loanBehaviorGrade } = this.info
      return (loanBehaviorGrade / 1000) * 100 || 0
    },
    // 申请准入置信度
    shenqing() {
      const { applyAccessConfidenceDegree } = this.info
      return (applyAccessConfidenceDegree / 100) * 100 || 0
    },
    // 消金额度置信度
    xiaojin() {
      const { consumeLoanConfidenceDegree } = this.info
      return (consumeLoanConfidenceDegree / 100) * 100 || 0
    },
    // 网贷额度置信度
    wangdai() {
      const { netLoanConfidenceDegree } = this.info
      return (netLoanConfidenceDegree / 100) * 100 || 0
    },
    // 贷款行为置信度
    daikuan() {
      const { loanBehaviorConfidenceDegree } = this.info
      return (loanBehaviorConfidenceDegree / 100) * 100 || 0
    }
  },
  mounted() {},

  methods: {
    // 申请准入分
    zhunruFormat(percentage) {
      const { applyAccessGrade } = this.info
      const str = applyAccessGrade || 0
      return `${str}分`
    },
    // 贷款行为分
    xingweiFormat(percentage) {
      const { loanBehaviorGrade } = this.info
      const str = loanBehaviorGrade || 0
      return `${str}分`
    },
    // 申请准入置信度
    shenqingFormat(percentage) {
      const { applyAccessConfidenceDegree } = this.info
      const str = applyAccessConfidenceDegree || 0
      return `${str}分`
    },
    // 消金额度置信度
    xiaojinFormat(percentage) {
      const { consumeLoanConfidenceDegree } = this.info
      const str = consumeLoanConfidenceDegree || 0
      return `${str}分`
    },
    // 网贷额度置信度
    wangdaiFormat(percentage) {
      const { netLoanConfidenceDegree } = this.info
      const str = netLoanConfidenceDegree || 0
      return `${str}分`
    },
    // 贷款行为置信度
    daikuanFormat(percentage) {
      const { loanBehaviorConfidenceDegree } = this.info
      const str = loanBehaviorConfidenceDegree || 0
      return `${str}分`
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-divider--horizontal {
    margin: 10px 0 !important;
  }
  .score {
    .title {
      text-align: center;
      font-size: 20px;
    }
    .proess {
      width: 80%;
      margin: 0 auto;
      .item1 {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .name {
          color: #222;
          margin-right: 20px;
          font-size: 16px;
          width: 150px;
          text-align: right;
        }
        .el-progress {
          display: flex;
          align-items: center;
        }
        .el-progress__text {
          min-width: 160px;
          font-size: 16px !important;
        }
      }
      .tip {
        color: #ff4949;
        padding: 0 10%;
        p {
          margin: 5px 0;
        }
      }
    }
  }
  .duotou {
    margin-top: 20px;
    .duotou-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .content {
      display: flex;
      // padding: 20px 0;
      .left {
        flex: 1;
        .top {
          background: #93d2f3;
          text-align: center;
          margin-bottom: 20px;
          padding: 15px 0;
          font-size: 16px;
          font-weight: 600;
          border-radius: 4px;
        }
        .bottom {
          border-radius: 4px;
          background: #93d2f3;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 15px 0;
          .item1 {
            font-size: 16px;
            .name {
              font-weight: 600;
              margin-bottom: 5px;
            }
            .value {
              text-align: center;
            }
          }
        }
      }
      .right {
        border-radius: 4px;
        margin-left: 20px;
        background: #93d2f3;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: 600;
        .name {
          margin-bottom: 5px;
        }
      }
    }
  }
  .shenqing {
    margin-top: 20px;
    .shenqing-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      // padding: 20px 0;
      .item1 {
        background: #93d2f3;
        padding: 15px 0;
        border-radius: 4px;
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
        }
        .value {
          text-align: center;
        }
      }
    }
  }
  .xinyong {
    margin-top: 20px;
    .xinyong-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      // padding: 20px 0;
      .item1 {
        background: #93d2f3;
        padding: 15px 0;
        border-radius: 4px;
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
        }
        .value {
          text-align: center;
        }
      }
    }
    .content-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      padding: 20px 0 20px 0;
      .item1 {
        background: #93d2f3;
        padding: 15px 0;
        border-radius: 4px;
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
        }
        .value {
          text-align: center;
        }
      }
    }
  }
  .xingwei {
    margin-top: 20px;
    .xingwei-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .content {
      border-left: 1px solid #dcdfe6;
      border-top: 1px solid #dcdfe6;
      .row {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        .item1 {
          padding: 10px 20px;
          border-right: 1px solid #dcdfe6;
          border-bottom: 1px solid #dcdfe6;
          text-align: center;
          font-size: 16px;
        }
      }
      .head {
        .item1 {
          background: #93d2f3;
        }
      }
    }
  }
}
</style>
