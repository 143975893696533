<template>
  <div class="wrapper">
    <admin-title title="探针"></admin-title>
    <div class="probe">
      <div class="probe-title">探针数据报告</div>
      <el-divider></el-divider>
      <div class="content">
        <div class="item1">
          <div class="name">最大逾期金额</div>
          <div class="value">{{ probe.maxOverdueAmount || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">睡眠机构数</div>
          <div class="value">{{ probe.sleepOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">最近履约时间</div>
          <div class="value">{{ probe.lastPerformanceTime || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">最长逾期天数</div>
          <div class="value">{{ probe.maxOverdueDay || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">当前逾期机构数</div>
          <div class="value">{{ probe.currOverdueOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">最大履约金额</div>
          <div class="value">{{ probe.maxPerformanceAmount || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">履约笔数</div>
          <div class="value">{{ probe.performanceNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">当前履约机构数</div>
          <div class="value">{{ probe.currPerformanceOrgNum || '暂无数据' }}</div>
        </div>
        <div class="item1">
          <div class="name">异常还款机构数</div>
          <div class="value">{{ probe.abnormalRepayOrgNum || '暂无数据' }}</div>
        </div>
      </div>
    </div>
    <!-- 全景档案 -->
<!--    <div class="document">-->
<!--      <div class="document-title">全景档案</div>-->
<!--      <el-divider></el-divider>-->
<!--      <div class="left-title">近期共债数据报告</div>-->
<!--      &lt;!&ndash; 近期共债数据报告 &ndash;&gt;-->
<!--      <div class="jinqi-content">-->
<!--        <div class="item1">-->
<!--          <div class="name">近1个月共债机构数</div>-->
<!--          <div class="value">{{ document.m1DebtOrgNum || '暂无数据' }}</div>-->
<!--        </div>-->
<!--        <div class="item1">-->
<!--          <div class="name">近1个月共债订单数</div>-->
<!--          <div class="value">{{ document.m1DebtOrderNum || '暂无数据' }}</div>-->
<!--        </div>-->
<!--        <div class="item1">-->
<!--          <div class="name">近1个月共债订单金额</div>-->
<!--          <div class="value">{{ document.m1DebtOrderAmount || '暂无数据' }}</div>-->
<!--        </div>-->
<!--        <div class="item1">-->
<!--          <div class="name">近1个月共债订单已还款金额</div>-->
<!--          <div class="value">{{ document.m1DebtOrderRepayAmount || '暂无数据' }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; 历史共债数据报告 &ndash;&gt;-->
<!--      <div class="left-title">历史共债数据报告</div>-->
<!--      <div class="lishi-content">-->
<!--        <div class="row head">-->
<!--          <div class="item1">共债统计时间</div>-->
<!--          <div class="item1">共债机构数</div>-->
<!--          <div class="item1">共债订单数</div>-->
<!--          <div class="item1">共债订单金额</div>-->
<!--          <div class="item1">共债订单已还款金额</div>-->
<!--          <div class="item1">疑似借新还旧</div>-->
<!--        </div>-->
<!--        <template v-if="document.debtList">-->
<!--          <div class="row" v-for="(item1, i) in document.debtList" :key="i">-->
<!--            <div class="item1">{{ item1.debtStatTime }}</div>-->
<!--            <div class="item1">{{ item1.debtOrgNum }}</div>-->
<!--            <div class="item1">{{ item1.debtOrderNum }}</div>-->
<!--            <div class="item1">{{ item1.debtOrderAmount }}</div>-->
<!--            <div class="item1">{{ item1.debtOrderRepayAmount }}</div>-->
<!--            <div class="item1">{{ item1.doubtNewRepayOld }}</div>-->
<!--          </div>-->
<!--        </template>-->
<!--      </div>-->
<!--      <template v-if="document.debtList">-->
<!--        <div class="no" v-if="document.debtList.length === 0">暂无数据</div>-->
<!--      </template>-->
<!--      <div class="tip">-->
<!--        * 数据说明:历史共债数据统计维度为6个月内，逾期6个月以上定义为坏账不计入。-->
<!--      </div>-->
<!--      &lt;!&ndash; 逾期档案报告总览 &ndash;&gt;-->
<!--      <div class="left-title">逾期档案报告总览</div>-->
<!--      <div class="yuqi-content">-->
<!--        <div class="item1">-->
<!--          <div class="name">近6个月逾期机构数</div>-->
<!--          <div class="value">{{ document.m6OverdueOrgNum }}</div>-->
<!--        </div>-->
<!--        <div class="item1">-->
<!--          <div class="name">近6个月逾期订单数</div>-->
<!--          <div class="value">{{ document.m6OverdueOrderNum }}</div>-->
<!--        </div>-->
<!--        <div class="item1">-->
<!--          <div class="name">近6个月逾期总金额</div>-->
<!--          <div class="value">{{ document.m6OverdueTotalAmount }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; 逾期详情 &ndash;&gt;-->
<!--      <div class="left-title">逾期详情</div>-->
<!--      <div class="yuqi-detail">-->
<!--        <div class="row head">-->
<!--          <div class="item1">逾期时间</div>-->
<!--          <div class="item1">逾期金额</div>-->
<!--          <div class="item1">逾期账期数</div>-->
<!--          <div class="item1">是否结清</div>-->
<!--        </div>-->
<!--        <template v-if="document.overdueList">-->
<!--          <div class="row" v-for="(item1, i) in document.overdueList" :key="i">-->
<!--            <div class="item1">{{ item1.overdueTime }}</div>-->
<!--            <div class="item1">{{ item1.overdueAmount }}</div>-->
<!--            <div class="item1">{{ item1.overdueBillNum }}</div>-->
<!--            <div class="item1">{{ item1.settleFlag }}</div>-->
<!--          </div>-->
<!--        </template>-->
<!--      </div>-->
<!--      <template v-if="document.overdueList">-->
<!--        <div class="no" v-if="document.overdueList.length === 0">暂无数据</div>-->
<!--      </template>-->

<!--      <div class="tip">-->
<!--        数据说明: 逾期账期数数值构成为M/S+账龄(1/2/3)，M为30/30+天的多期借贷账期，S为7/14/21天等超短期现金贷帐期。-->
<!--        如帐期为7天，S0代表0-7天，S1代表8-14天。如帐期为30天，M0代表0-30天以此类推。-->
<!--      </div>-->
<!--      <div style="height:200px"></div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'XinyanProbe',
  props: {
    probe: {
      type: Object,
      default: () => {}
    },
    document: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-divider--horizontal {
    margin: 10px 0 !important;
  }
  .probe {
    .probe-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding: 0 0 20px 0;
      .item1 {
        background: #93d2f3;
        padding: 15px 0;
        border-radius: 4px;
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
        }
        .value {
          text-align: center;
        }
      }
    }
  }
  .document {
    .document-title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .left-title {
      font-size: 20px;
      font-weight: 600;
    }
    .jinqi-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      padding: 20px 0;
      .item1 {
        background: #93d2f3;
        padding: 15px 0;
        border-radius: 4px;
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: center;
        }
        .value {
          text-align: center;
        }
      }
    }
  }
  .lishi-content {
    margin-top: 15px;
    border-left: 1px solid #dcdfe6;
    border-top: 1px solid #dcdfe6;
    .row {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      justify-content: center;
      .item1 {
        padding: 10px 20px;
        border-right: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
        text-align: center;
        font-size: 16px;
      }
    }
    .head {
      .item1 {
        background: #93d2f3;
      }
    }
  }
  .tip {
    text-align: center;
    margin: 15px 0;
    color: #ff4949;
  }
  .yuqi-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px 0;
    .item1 {
      background: #93d2f3;
      padding: 15px 0;
      border-radius: 4px;
      .name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        text-align: center;
      }
      .value {
        text-align: center;
      }
    }
  }
  .yuqi-detail {
    margin-top: 15px;
    border-left: 1px solid #dcdfe6;
    border-top: 1px solid #dcdfe6;
    .row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      .item1 {
        padding: 10px 20px;
        border-right: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
        text-align: center;
        font-size: 16px;
      }
    }
    .head {
      .item1 {
        background: #93d2f3;
      }
    }
  }
  .no {
    padding: 20px 0;
    text-align: center;
  }
}
</style>
