<template>
  <div class="wrapper">
    <el-checkbox-group v-model="periodList" @change="changeSelect">
      <el-checkbox v-for="item in billPeriodNum" :key="item" :label="item">第{{ item }}期（￥：{{ eachRentPrice }}）</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>

export default {
  name: 'Period',
  props: ['billPeriodNum', 'eachRentPrice', ],
  data() {
    return {
      periodList: []
    }
  },
  computed: {
   
  },
  watch: {
 
  },
  mounted() {},

  methods: {
    changeSelect() {
      this.$store.commit('UPDATE_PERIOD', this.periodList)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  background: #fafafa;
  padding: 15px;
}
</style>
